body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  image-rendering: pixelated;
  image-rendering: crisp-edges;

  background-color: white;
  background-image: url('./background.png');
  background-repeat: repeat-y;
  background-position: top center;
}

body {
  background-size: auto;
}

@media only screen and (max-width: 720px) {
  body {
    background-size: 488px;
  }
}

@media only screen and (min-width: 720px) {
  body {
    background-size: 976px;
  }
}

@media only screen and (min-width: 1080px) {
  body {
    background-size: 1464px;
  }
}

@media only screen and (min-width: 1440px) {
  body {
    background-size: 1952px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-section {
  display: flex;
  margin-bottom: 0.375em;
}

.app-section-split {
  flex: 1 1 50%;
}

.portrait-container {
  padding-left: 0;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  flex: 0 0 0;
}

.portrait {
  display: block;
  height: 6em;
}

.biography-paragraph:not(:first-of-type) {
  margin-top: 0.5em;
}