@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
.retro-container {
  margin: 0 auto;
  margin-top: 0.25em;
}

@media only screen and (max-width: 720px) {
  .retro-container {
    font-size: 8px;
    width: 360px;
  }
}

@media only screen and (min-width: 720px) {
  .retro-container {
    font-size: 16px;
    width: 720px;
  }
}

@media only screen and (min-width: 1080px) {
  .retro-container {
    font-size: 24px;
    width: 1080px;
  }
}

@media only screen and (min-width: 1440px) {
  .retro-container {
    font-size: 32px;
    width: 1440px;
  }
}
.retro-experience-container {
  display: flex;
}
  
.retro-experience-icon-container {
  flex: 0 0;
  padding-left: 0.375em;
  padding-right: 0.75em;
}

.retro-experience-icon {
  height: 4em;
  width: 4em;
}

.retro-experience-detail {
  flex: 0 0 1;
}
.retro-skill-container {
  display: flex;
}

.retro-skill-name {
  flex: 1 1 100%;
}

.retro-skill-level {
  flex: 0 0;
  text-align: right;
}

.retro-skill-point-container {
  flex: 0 0;
}

.retro-skill-points {
  width: 4.375em;
}

.retro-skill-point {
  height: 0.875em;
  vertical-align: text-top;
}

.retro-skill-point-container .retro-skill-point:not(:first-child) {
  padding-left: 0.125em;
}
.retro-theme {
  background-color: black;
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
  font-family: 'Press Start 2P';
  color: white;
  line-height: 1.5;
  font-smooth: none;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: none;
}

.retro-theme ul {
  list-style-type: '*';
}
.retro-window {
  position: relative;

  width: 100%;

  border: 0.25em solid transparent;
  border-image-source: url(/static/media/border.45b08b48.svg);
  border-image-slice: calc(1 / 3 * 100%);
  padding-top: 1.125em;
  padding-bottom: 1.125em;
  padding-left: 0.375em;
  padding-right: 0.375em;
}

.retro-window-title {
  position: absolute;

  background-color: black;

  line-height: 1;

  top: -0.25em;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  
  padding-left: 0.25em;
  padding-right: 0.125em;
}

.retro-window-left-clipped {
  display: block;
  margin-left: 0.375em;
  border-left-width: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  -ms-interpolation-mode: nearest-neighbor;
  
      image-rendering: -moz-crisp-edges;
  
      image-rendering: pixelated;
  image-rendering: crisp-edges;

  background-color: white;
  background-image: url(/static/media/background.e6828c89.png);
  background-repeat: repeat-y;
  background-position: top center;
}

body {
  background-size: auto;
}

@media only screen and (max-width: 720px) {
  body {
    background-size: 488px;
  }
}

@media only screen and (min-width: 720px) {
  body {
    background-size: 976px;
  }
}

@media only screen and (min-width: 1080px) {
  body {
    background-size: 1464px;
  }
}

@media only screen and (min-width: 1440px) {
  body {
    background-size: 1952px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-section {
  display: flex;
  margin-bottom: 0.375em;
}

.app-section-split {
  flex: 1 1 50%;
}

.portrait-container {
  padding-left: 0;
  padding-top: 0.375em;
  padding-bottom: 0.375em;
  flex: 0 0;
}

.portrait {
  display: block;
  height: 6em;
}

.biography-paragraph:not(:first-of-type) {
  margin-top: 0.5em;
}
