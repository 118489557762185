.retro-window {
  position: relative;

  width: 100%;

  border: 0.25em solid transparent;
  border-image-source: url('./border.svg');
  border-image-slice: calc(1 / 3 * 100%);
  padding-top: 1.125em;
  padding-bottom: 1.125em;
  padding-left: 0.375em;
  padding-right: 0.375em;
}

.retro-window-title {
  position: absolute;

  background-color: black;

  line-height: 1;

  top: -0.25em;
  left: 50%;
  transform: translateX(-50%);
  
  padding-left: 0.25em;
  padding-right: 0.125em;
}

.retro-window-left-clipped {
  display: block;
  margin-left: 0.375em;
  border-left-width: 0;
}